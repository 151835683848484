/** @jsx jsx */
import { jsx } from "theme-ui"
import { useEffect, useState } from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import Modal from "react-modal"
import Obfuscate from "react-obfuscate"
import { FaInfo, FaTimes, FaChevronDown } from "react-icons/fa"
import { CgScrollV } from "react-icons/cg"
import SponorPlaceholder from "../../images/sponsor_placeholder.jpg"

const Sponsors = ({ sponsors }) => {
  const [stateExecutive, setstateExecutive] = useState(false)
  const [statePlatinum, setstatePlatinum] = useState(false)
  const [stateGold, setstateGold] = useState(false)
  const [stateSilver, setstateSilver] = useState(false)
  const [stateTalks, setstateTalks] = useState(false)
  const [stateSix, setstateSix] = useState(false)

  useEffect(() => {
    const filterSponsors = () => {
      for (let i = 0; i < sponsors.simpleEvents?.ranks?.length; i++) {
        if (sponsors.simpleEvents?.ranks[i]) {
          setstateSix(true)
        }
        if (sponsors.simpleEvents?.ranks[i]) {
          setstateTalks(true)
        }
        if (sponsors.simpleEvents?.ranks[i]) {
          setstateSilver(true)
        }
        if (sponsors.simpleEvents?.ranks[i]) {
          setstateGold(true)
        }
        if (sponsors.simpleEvents?.ranks[i]) {
          setstatePlatinum(true)
        }
        if (sponsors.simpleEvents?.ranks[i]) {
          setstateExecutive(true)
        } else {
          console.log(" ")
        }
      }
    }
    filterSponsors()
  }, [])

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  }

  const [modalIsOpen, setIsOpen] = useState()

  function openModal(id) {
    setIsOpen(id)
  }
  function closeModal() {
    setIsOpen()
  }

  const executive = [sponsors.simpleEvents.ranks[0]]
  const platinum = [sponsors.simpleEvents.ranks[1]]
  const gold = [sponsors.simpleEvents.ranks[2]]
  const silver = [sponsors.simpleEvents.ranks[3]]
  const talks = [sponsors.simpleEvents.ranks[4]]
  const six = [sponsors.simpleEvents.ranks[5]]

  return (
    <div>
      {sponsors.simpleEvents.ranks ? (
        <div id="sponsors" className="grid-container posts" sx={{ pt: `50px` }}>
          <h2 sx={{ mb: `1rem` }}>Sponsors</h2>
          <p sx={{ mb: `5px` }}>
            (<FaInfo /> Click on a logo to get more information)
          </p>
          <Link to="/sponsors">
            <button className="gap-xl">tinyML Sponsor Directory</button>
          </Link>
          {sponsors.simpleEvents.ranks[0] ? (
            <div>
              {sponsors.simpleEvents.ranks[0].sponsor?.length > 0 ? (
                <div className="gap-xxl">
                  {stateExecutive ? (
                    <div>
                      <h3>
                        {sponsors?.simpleEvents?.sponsorLevelTitles?.levelOne}
                      </h3>
                      {executive?.map((f, index) => {
                        return (
                          <div
                            key={index}
                            sx={{
                              display: `grid`,
                              gridTemplateColumns: [
                                "12fr",
                                "6fr 6fr",
                                "repeat(3,1fr)",
                              ],
                              gridTemplateRows: `repeat(auto)`,
                              width: `100%`,
                              gridColumnGap: `1rem`,
                              flexWrap: `wrap`,
                              rowGap: `30px`,
                              alignItems: `center`,
                            }}
                          >
                            {f?.sponsor?.map(e => {
                              return (
                                <div
                                  key={e.id}
                                  className="sponsors-five"
                                  sx={{
                                    display: `flex`,
                                    position: `relative`,
                                    background: `#fff`,
                                    flexDirection: `column`,
                                    justifyContent: `center`,
                                    padding: `1rem`,
                                    textAlign: `center`,
                                    textAlign: `-webkit-center`,
                                    transition: `300ms`,
                                    height: `100%`,
                                    boxShadow: `0px 0px 60px rgba(0, 0, 0, 0.08)`,
                                    "&:hover": {
                                      boxShadow: `0px 0px 60px rgba(0, 0, 0, 0.2)`,
                                    },
                                  }}
                                >
                                  <a
                                    onClick={() => openModal(e?.id)}
                                    sx={{
                                      cursor: `pointer`,
                                    }}
                                  >
                                    {e?.featuredImage !== null || "" ? (
                                      <Img
                                        sx={{ margin: `5px` }}
                                        fluid={
                                          e.featuredImage?.node?.localFile
                                            ?.childImageSharp?.fluid
                                        }
                                      />
                                    ) : (
                                      <img
                                        src={SponorPlaceholder}
                                        alt="Sponsor Placeholder"
                                        sx={{ margin: `5px` }}
                                      />
                                    )}
                                  </a>
                                  <Modal
                                    isOpen={modalIsOpen === e?.id}
                                    onRequestClose={closeModal}
                                    closeTimeoutMS={500}
                                    style={customStyles}
                                    ariaHideApp={false}
                                    contentLabel={e?.id}
                                  >
                                    <div sx={{ pt: `2rem` }}>
                                      <FaTimes
                                        onClick={closeModal}
                                        style={{
                                          position: `absolute`,
                                          right: `15px`,
                                          top: `15px`,
                                          cursor: `pointer`,
                                        }}
                                      />
                                      <div
                                        className="grid-container"
                                        onClick={closeModal}
                                      >
                                        <div
                                          sx={{
                                            display: `grid`,
                                            gridTemplateColumns: [
                                              "12fr",
                                              "12fr",
                                              "4fr 8fr",
                                            ],
                                            gridTemplateRows: `repeat(auto)`,
                                            rowGap: `30px`,
                                            gridColumnGap: `3%`,
                                          }}
                                          key={e.id}
                                          className="sponsors-one"
                                        >
                                          <div>
                                            <div>
                                              <a
                                                href={
                                                  e.sponsorDetails?.sponsorLink
                                                }
                                                onClick="#"
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {e?.featuredImage !== null ||
                                                "" ? (
                                                  <Img
                                                    sx={{
                                                      maxWidth: [
                                                        "60%",
                                                        "50%",
                                                        "70%",
                                                      ],
                                                      mb: `3rem`,
                                                    }}
                                                    fluid={
                                                      e.featuredImage?.node
                                                        ?.localFile
                                                        ?.childImageSharp?.fluid
                                                    }
                                                  />
                                                ) : (
                                                  <img
                                                    src={SponorPlaceholder}
                                                    alt="Sponsor Placeholder"
                                                    sx={{
                                                      maxWidth: [
                                                        "60%",
                                                        "50%",
                                                        "70%",
                                                      ],
                                                      mb: `3rem`,
                                                    }}
                                                  />
                                                )}
                                              </a>
                                            </div>
                                            {e?.sponsorDetails?.contactInfo ? (
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    e?.sponsorDetails
                                                      ?.contactInfo,
                                                }}
                                                sx={{ mb: `10px` }}
                                              ></div>
                                            ) : null}
                                            {e?.sponsorDetails?.contactEmail !=
                                            null ? (
                                              <div onClick="#">
                                                <Obfuscate
                                                  href={`mailto:${e?.sponsorDetails?.contactEmail}`}
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  <button
                                                    sx={{
                                                      width: `200px`,
                                                      mb: `1rem`,
                                                    }}
                                                  >
                                                    {
                                                      e?.sponsorDetails
                                                        ?.emailButtonLabel
                                                    }
                                                  </button>
                                                </Obfuscate>
                                              </div>
                                            ) : null}
                                            {e?.sponsorDetails?.contactUrl !=
                                            null ? (
                                              <div onClick="#">
                                                <a
                                                  href={
                                                    e?.sponsorDetails
                                                      ?.contactUrl
                                                  }
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  <button
                                                    sx={{ width: `200px` }}
                                                  >
                                                    {
                                                      e?.sponsorDetails
                                                        ?.urlButtonLabel
                                                    }
                                                  </button>
                                                </a>
                                              </div>
                                            ) : null}
                                          </div>
                                          <div sx={{ maxWidth: `96%` }}>
                                            <div>
                                              {e?.sponsorDetails?.upperImage ||
                                              e?.sponsorDetails?.upperVideo !=
                                                null ? (
                                                <div>
                                                  {e?.sponsorDetails
                                                    ?.upperMediaFileType ===
                                                  "Image" ? (
                                                    <Img
                                                      fluid={
                                                        e?.sponsorDetails
                                                          ?.upperImage
                                                          ?.localFile
                                                          ?.childImageSharp
                                                          ?.fluid
                                                      }
                                                      sx={{ mb: `50px` }}
                                                    />
                                                  ) : null}{" "}
                                                  {e?.sponsorDetails
                                                    ?.upperMediaFileType ===
                                                  "Video" ? (
                                                    <iframe
                                                      width="100%"
                                                      height="450"
                                                      src={
                                                        e?.sponsorDetails
                                                          ?.upperVideo
                                                      }
                                                      frameBorder="0"
                                                      title="video"
                                                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                      allowFullScreen
                                                      loading="lazy"
                                                    ></iframe>
                                                  ) : null}
                                                </div>
                                              ) : null}
                                            </div>
                                            {e?.sponsorDetails
                                              ?.sponsorDescription ? (
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    e?.sponsorDetails
                                                      ?.sponsorDescription,
                                                }}
                                                sx={{ mb: `50px` }}
                                              ></div>
                                            ) : null}
                                            {e?.sponsorDetails?.lowerImage ||
                                            e?.sponsorDetails
                                              ?.lowerVideoCopy ? (
                                              <div>
                                                {e?.sponsorDetails
                                                  ?.lowerMediaFileType ===
                                                "Image" ? (
                                                  <Img
                                                    fluid={
                                                      e?.sponsorDetails
                                                        ?.lowerImage?.localFile
                                                        ?.childImageSharp?.fluid
                                                    }
                                                    sx={{ mb: `50px` }}
                                                  />
                                                ) : null}{" "}
                                                {e?.sponsorDetails
                                                  ?.lowerMediaFileType ===
                                                "Video" ? (
                                                  <iframe
                                                    width="100%"
                                                    height="450"
                                                    src={
                                                      e?.sponsorDetails
                                                        ?.lowerVideoCopy
                                                    }
                                                    frameBorder="0"
                                                    title="video"
                                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                    loading="lazy"
                                                  ></iframe>
                                                ) : null}
                                              </div>
                                            ) : null}
                                            {e.sponsorDetails
                                              .sponsorDescription ||
                                            e.sponsorDetails.lowerImage ||
                                            e.sponsorDetails.lowerVideoCopy !=
                                              null ? (
                                              <div
                                                sx={{
                                                  position: `absolute`,
                                                  right: `0`,
                                                  top: `50%`,
                                                }}
                                              >
                                                <CgScrollV size={40} />
                                                <p
                                                  sx={{
                                                    margin: `7px 0px 0px -3px`,
                                                    transform: `rotate(90deg)`,
                                                    fontWeight: `bold`,
                                                  }}
                                                >
                                                  Scroll
                                                </p>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Modal>
                                </div>
                              )
                            })}
                          </div>
                        )
                      })}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          ) : null}
          {sponsors.simpleEvents.ranks[1] ? (
            <div>
              {sponsors.simpleEvents.ranks[1].sponsor?.length > 0 ? (
                <div className="gap-xxl">
                  {statePlatinum ? (
                    <div>
                      <h3>
                        {sponsors?.simpleEvents?.sponsorLevelTitles?.levelTwo}
                      </h3>
                      {platinum.map((f, index) => {
                        return (
                          <div
                            key={index}
                            sx={{
                              display: `grid`,
                              gridTemplateColumns: [
                                `repeat(1, 1fr)`,
                                `repeat(2, 1fr)`,
                                `repeat(3, 1fr)`,
                              ],
                              gridTemplateRows: `repeat(auto)`,
                              width: `100%`,
                              gridColumnGap: `1rem`,
                              flexWrap: `wrap`,
                              rowGap: `30px`,
                              alignItems: `center`,
                            }}
                          >
                            {f?.sponsor?.map(e => {
                              return (
                                <div
                                  key={e.id}
                                  className="sponsors-four"
                                  sx={{
                                    display: `flex`,
                                    position: `relative`,
                                    background: `#fff`,
                                    flexDirection: `column`,
                                    justifyContent: `center`,
                                    padding: `1rem`,
                                    textAlign: `center`,
                                    textAlign: `-webkit-center`,
                                    transition: `300ms`,
                                    height: `100%`,
                                    boxShadow: `0px 0px 60px rgba(0, 0, 0, 0.08)`,
                                    "&:hover": {
                                      boxShadow: `0px 0px 60px rgba(0, 0, 0, 0.2)`,
                                    },
                                  }}
                                >
                                  <a
                                    onClick={() => openModal(e?.id)}
                                    sx={{
                                      cursor: `pointer`,
                                    }}
                                  >
                                    {e?.featuredImage !== null || "" ? (
                                      <Img
                                        sx={{ margin: `5px` }}
                                        fluid={
                                          e.featuredImage?.node?.localFile
                                            ?.childImageSharp?.fluid
                                        }
                                      />
                                    ) : (
                                      <img
                                        src={SponorPlaceholder}
                                        alt="Sponsor Placeholder"
                                        sx={{ margin: `5px` }}
                                      />
                                    )}
                                  </a>
                                  <Modal
                                    isOpen={modalIsOpen === e?.id}
                                    onRequestClose={closeModal}
                                    closeTimeoutMS={500}
                                    style={customStyles}
                                    ariaHideApp={false}
                                    contentLabel={e?.id}
                                  >
                                    <div sx={{ pt: `2rem` }}>
                                      <FaTimes
                                        onClick={closeModal}
                                        style={{
                                          position: `absolute`,
                                          right: `15px`,
                                          top: `15px`,
                                          cursor: `pointer`,
                                        }}
                                      />
                                      <div
                                        className="grid-container"
                                        onClick={closeModal}
                                      >
                                        <div
                                          sx={{
                                            display: `grid`,
                                            gridTemplateColumns: [
                                              "12fr",
                                              "12fr",
                                              "4fr 8fr",
                                            ],
                                            gridTemplateRows: `repeat(auto)`,
                                            rowGap: `30px`,
                                            gridColumnGap: `3%`,
                                          }}
                                          key={e.id}
                                          className="sponsors-one"
                                        >
                                          <div>
                                            <div>
                                              <a
                                                href={
                                                  e.sponsorDetails?.sponsorLink
                                                }
                                                onClick="#"
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {e?.featuredImage !== null ||
                                                "" ? (
                                                  <Img
                                                    sx={{
                                                      maxWidth: [
                                                        "60%",
                                                        "50%",
                                                        "70%",
                                                      ],
                                                      mb: `3rem`,
                                                    }}
                                                    fluid={
                                                      e.featuredImage?.node
                                                        ?.localFile
                                                        ?.childImageSharp?.fluid
                                                    }
                                                  />
                                                ) : (
                                                  <img
                                                    src={SponorPlaceholder}
                                                    alt="Sponsor Placeholder"
                                                    sx={{
                                                      maxWidth: [
                                                        "60%",
                                                        "50%",
                                                        "70%",
                                                      ],
                                                      mb: `3rem`,
                                                    }}
                                                  />
                                                )}
                                              </a>
                                            </div>
                                            {e?.sponsorDetails?.contactInfo ? (
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    e?.sponsorDetails
                                                      ?.contactInfo,
                                                }}
                                                sx={{ mb: `10px` }}
                                              ></div>
                                            ) : null}
                                            {e?.sponsorDetails?.contactEmail !=
                                            null ? (
                                              <div onClick="#">
                                                <Obfuscate
                                                  href={`mailto:${e?.sponsorDetails?.contactEmail}`}
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  <button
                                                    sx={{
                                                      width: `200px`,
                                                      mb: `1rem`,
                                                    }}
                                                  >
                                                    {
                                                      e?.sponsorDetails
                                                        ?.emailButtonLabel
                                                    }
                                                  </button>
                                                </Obfuscate>
                                              </div>
                                            ) : null}
                                            {e?.sponsorDetails?.contactUrl !=
                                            null ? (
                                              <div onClick="#">
                                                <a
                                                  href={
                                                    e?.sponsorDetails
                                                      ?.contactUrl
                                                  }
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  <button
                                                    sx={{ width: `200px` }}
                                                  >
                                                    {
                                                      e?.sponsorDetails
                                                        ?.urlButtonLabel
                                                    }
                                                  </button>
                                                </a>
                                              </div>
                                            ) : null}
                                          </div>
                                          <div sx={{ maxWidth: `96%` }}>
                                            <div>
                                              {e?.sponsorDetails?.upperImage ||
                                              e?.sponsorDetails?.upperVideo !=
                                                null ? (
                                                <div>
                                                  {e?.sponsorDetails
                                                    ?.upperMediaFileType ===
                                                  "Image" ? (
                                                    <Img
                                                      fluid={
                                                        e?.sponsorDetails
                                                          ?.upperImage
                                                          ?.localFile
                                                          ?.childImageSharp
                                                          ?.fluid
                                                      }
                                                      sx={{ mb: `50px` }}
                                                    />
                                                  ) : null}{" "}
                                                  {e?.sponsorDetails
                                                    ?.upperMediaFileType ===
                                                  "Video" ? (
                                                    <iframe
                                                      width="100%"
                                                      height="450"
                                                      src={
                                                        e?.sponsorDetails
                                                          ?.upperVideo
                                                      }
                                                      frameBorder="0"
                                                      title="video"
                                                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                      allowFullScreen
                                                      loading="lazy"
                                                    ></iframe>
                                                  ) : null}
                                                </div>
                                              ) : null}
                                            </div>
                                            {e?.sponsorDetails
                                              ?.sponsorDescription ? (
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    e?.sponsorDetails
                                                      ?.sponsorDescription,
                                                }}
                                                sx={{ mb: `50px` }}
                                              ></div>
                                            ) : null}
                                            {e?.sponsorDetails?.lowerImage ||
                                            e?.sponsorDetails
                                              ?.lowerVideoCopy ? (
                                              <div>
                                                {e?.sponsorDetails
                                                  ?.lowerMediaFileType ===
                                                "Image" ? (
                                                  <Img
                                                    fluid={
                                                      e?.sponsorDetails
                                                        ?.lowerImage?.localFile
                                                        ?.childImageSharp?.fluid
                                                    }
                                                    sx={{ mb: `50px` }}
                                                  />
                                                ) : null}{" "}
                                                {e?.sponsorDetails
                                                  ?.lowerMediaFileType ===
                                                "Video" ? (
                                                  <iframe
                                                    width="100%"
                                                    height="450"
                                                    src={
                                                      e?.sponsorDetails
                                                        ?.lowerVideoCopy
                                                    }
                                                    frameBorder="0"
                                                    title="video"
                                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                    loading="lazy"
                                                  ></iframe>
                                                ) : null}
                                              </div>
                                            ) : null}
                                            {e.sponsorDetails
                                              .sponsorDescription ||
                                            e.sponsorDetails.lowerImage ||
                                            e.sponsorDetails.lowerVideoCopy !=
                                              null ? (
                                              <div
                                                sx={{
                                                  position: `absolute`,
                                                  right: `0`,
                                                  top: `50%`,
                                                }}
                                              >
                                                <CgScrollV size={40} />
                                                <p
                                                  sx={{
                                                    margin: `7px 0px 0px -3px`,
                                                    transform: `rotate(90deg)`,
                                                    fontWeight: `bold`,
                                                  }}
                                                >
                                                  Scroll
                                                </p>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Modal>
                                </div>
                              )
                            })}
                          </div>
                        )
                      })}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          ) : null}
          {sponsors.simpleEvents.ranks[2] ? (
            <div>
              {sponsors.simpleEvents.ranks[2].sponsor?.length > 0 ? (
                <div className="gap-xxl">
                  {stateGold ? (
                    <div>
                      <h3>
                        {sponsors?.simpleEvents?.sponsorLevelTitles?.levelThree}
                      </h3>
                      {gold.map((f, index) => {
                        return (
                          <div
                            key={index}
                            sx={{
                              display: `grid`,
                              gridTemplateColumns: [
                                `repeat(1, 1fr)`,
                                `repeat(2, 1fr)`,
                                `repeat(3, 1fr)`,
                              ],
                              gridTemplateRows: `repeat(auto)`,
                              width: `100%`,
                              gridColumnGap: `1rem`,
                              flexWrap: `wrap`,
                              rowGap: `30px`,
                              alignItems: `center`,
                            }}
                          >
                            {f?.sponsor?.map(e => {
                              return (
                                <div
                                  key={e.id}
                                  className="sponsors-three"
                                  sx={{
                                    display: `flex`,
                                    position: `relative`,
                                    background: `#fff`,
                                    flexDirection: `column`,
                                    justifyContent: `center`,
                                    padding: `1rem`,
                                    textAlign: `center`,
                                    textAlign: `-webkit-center`,
                                    transition: `300ms`,
                                    height: `100%`,
                                    boxShadow: `0px 0px 60px rgba(0, 0, 0, 0.08)`,
                                    "&:hover": {
                                      boxShadow: `0px 0px 60px rgba(0, 0, 0, 0.2)`,
                                    },
                                  }}
                                >
                                  <a
                                    onClick={() => openModal(e?.id)}
                                    sx={{
                                      cursor: `pointer`,
                                    }}
                                  >
                                    {e?.featuredImage !== null || "" ? (
                                      <Img
                                        sx={{ margin: `5px` }}
                                        fluid={
                                          e.featuredImage?.node?.localFile
                                            ?.childImageSharp?.fluid
                                        }
                                      />
                                    ) : (
                                      <img
                                        src={SponorPlaceholder}
                                        alt="Sponsor Placeholder"
                                        sx={{ margin: `5px` }}
                                      />
                                    )}
                                  </a>
                                  <Modal
                                    isOpen={modalIsOpen === e?.id}
                                    onRequestClose={closeModal}
                                    closeTimeoutMS={500}
                                    style={customStyles}
                                    ariaHideApp={false}
                                    contentLabel={e?.id}
                                  >
                                    <div sx={{ pt: `2rem` }}>
                                      <FaTimes
                                        onClick={closeModal}
                                        style={{
                                          position: `absolute`,
                                          right: `15px`,
                                          top: `15px`,
                                          cursor: `pointer`,
                                        }}
                                      />
                                      <div
                                        className="grid-container"
                                        onClick={closeModal}
                                      >
                                        <div
                                          sx={{
                                            display: `grid`,
                                            gridTemplateColumns: [
                                              "12fr",
                                              "12fr",
                                              "4fr 8fr",
                                            ],
                                            gridTemplateRows: `repeat(auto)`,
                                            rowGap: `30px`,
                                            gridColumnGap: `3%`,
                                          }}
                                          key={e.id}
                                          className="sponsors-one"
                                        >
                                          <div>
                                            <div>
                                              <a
                                                href={
                                                  e.sponsorDetails?.sponsorLink
                                                }
                                                onClick="#"
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {e?.featuredImage !== null ||
                                                "" ? (
                                                  <Img
                                                    sx={{
                                                      maxWidth: [
                                                        "60%",
                                                        "50%",
                                                        "70%",
                                                      ],
                                                      mb: `3rem`,
                                                    }}
                                                    fluid={
                                                      e.featuredImage?.node
                                                        ?.localFile
                                                        ?.childImageSharp?.fluid
                                                    }
                                                  />
                                                ) : (
                                                  <img
                                                    src={SponorPlaceholder}
                                                    alt="Sponsor Placeholder"
                                                    sx={{
                                                      maxWidth: [
                                                        "60%",
                                                        "50%",
                                                        "70%",
                                                      ],
                                                      mb: `3rem`,
                                                    }}
                                                  />
                                                )}
                                              </a>
                                            </div>
                                            {e?.sponsorDetails?.contactInfo ? (
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    e?.sponsorDetails
                                                      ?.contactInfo,
                                                }}
                                                sx={{ mb: `10px` }}
                                              ></div>
                                            ) : null}
                                            {e?.sponsorDetails?.contactEmail !=
                                            null ? (
                                              <div onClick="#">
                                                <Obfuscate
                                                  href={`mailto:${e?.sponsorDetails?.contactEmail}`}
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  <button
                                                    sx={{
                                                      width: `200px`,
                                                      mb: `1rem`,
                                                    }}
                                                  >
                                                    {
                                                      e?.sponsorDetails
                                                        ?.emailButtonLabel
                                                    }
                                                  </button>
                                                </Obfuscate>
                                              </div>
                                            ) : null}
                                            {e?.sponsorDetails?.contactUrl !=
                                            null ? (
                                              <div onClick="#">
                                                <a
                                                  href={
                                                    e?.sponsorDetails
                                                      ?.contactUrl
                                                  }
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  <button
                                                    sx={{ width: `200px` }}
                                                  >
                                                    {
                                                      e?.sponsorDetails
                                                        ?.urlButtonLabel
                                                    }
                                                  </button>
                                                </a>
                                              </div>
                                            ) : null}
                                          </div>
                                          <div sx={{ maxWidth: `96%` }}>
                                            <div>
                                              {e?.sponsorDetails?.upperImage ||
                                              e?.sponsorDetails?.upperVideo !=
                                                null ? (
                                                <div>
                                                  {e?.sponsorDetails
                                                    ?.upperMediaFileType ===
                                                  "Image" ? (
                                                    <Img
                                                      fluid={
                                                        e?.sponsorDetails
                                                          ?.upperImage
                                                          ?.localFile
                                                          ?.childImageSharp
                                                          ?.fluid
                                                      }
                                                      sx={{ mb: `50px` }}
                                                    />
                                                  ) : null}{" "}
                                                  {e?.sponsorDetails
                                                    ?.upperMediaFileType ===
                                                  "Video" ? (
                                                    <iframe
                                                      width="100%"
                                                      height="450"
                                                      src={
                                                        e?.sponsorDetails
                                                          ?.upperVideo
                                                      }
                                                      frameBorder="0"
                                                      title="video"
                                                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                      allowFullScreen
                                                      loading="lazy"
                                                    ></iframe>
                                                  ) : null}
                                                </div>
                                              ) : null}
                                            </div>
                                            {e?.sponsorDetails
                                              ?.sponsorDescription ? (
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    e?.sponsorDetails
                                                      ?.sponsorDescription,
                                                }}
                                                sx={{ mb: `50px` }}
                                              ></div>
                                            ) : null}
                                            {e?.sponsorDetails?.lowerImage ||
                                            e?.sponsorDetails
                                              ?.lowerVideoCopy ? (
                                              <div>
                                                {e?.sponsorDetails
                                                  ?.lowerMediaFileType ===
                                                "Image" ? (
                                                  <Img
                                                    fluid={
                                                      e?.sponsorDetails
                                                        ?.lowerImage?.localFile
                                                        ?.childImageSharp?.fluid
                                                    }
                                                    sx={{ mb: `50px` }}
                                                  />
                                                ) : null}{" "}
                                                {e?.sponsorDetails
                                                  ?.lowerMediaFileType ===
                                                "Video" ? (
                                                  <iframe
                                                    width="100%"
                                                    height="450"
                                                    src={
                                                      e?.sponsorDetails
                                                        ?.lowerVideoCopy
                                                    }
                                                    frameBorder="0"
                                                    title="video"
                                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                    loading="lazy"
                                                  ></iframe>
                                                ) : null}
                                              </div>
                                            ) : null}
                                            {e.sponsorDetails
                                              .sponsorDescription ||
                                            e.sponsorDetails.lowerImage ||
                                            e.sponsorDetails.lowerVideoCopy !=
                                              null ? (
                                              <div
                                                sx={{
                                                  position: `absolute`,
                                                  right: `0`,
                                                  top: `50%`,
                                                }}
                                              >
                                                <CgScrollV size={40} />
                                                <p
                                                  sx={{
                                                    margin: `7px 0px 0px -3px`,
                                                    transform: `rotate(90deg)`,
                                                    fontWeight: `bold`,
                                                  }}
                                                >
                                                  Scroll
                                                </p>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Modal>
                                </div>
                              )
                            })}
                          </div>
                        )
                      })}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          ) : null}
          {sponsors.simpleEvents.ranks[3] ? (
            <div>
              {sponsors.simpleEvents.ranks[3].sponsor?.length > 0 ? (
                <div className="gap-xxl">
                  {stateSilver ? (
                    <div>
                      <h3>
                        {sponsors?.simpleEvents?.sponsorLevelTitles?.levelFour}
                      </h3>
                      {silver.map((f, index) => {
                        return (
                          <div
                            key={index}
                            sx={{
                              display: `grid`,
                              gridTemplateColumns: [
                                `repeat(1, 1fr)`,
                                `repeat(2, 1fr)`,
                                `repeat(3, 1fr)`,
                              ],
                              gridTemplateRows: `repeat(auto)`,
                              width: `100%`,
                              gridColumnGap: `1rem`,
                              flexWrap: `wrap`,
                              rowGap: `30px`,
                              alignItems: `center`,
                            }}
                          >
                            {f?.sponsor?.map(e => {
                              return (
                                <div
                                  key={e.id}
                                  className="sponsors-two"
                                  sx={{
                                    display: `flex`,
                                    position: `relative`,
                                    background: `#fff`,
                                    flexDirection: `column`,
                                    justifyContent: `center`,
                                    padding: `1rem`,
                                    textAlign: `center`,
                                    textAlign: `-webkit-center`,
                                    transition: `300ms`,
                                    height: `100%`,
                                    boxShadow: `0px 0px 60px rgba(0, 0, 0, 0.08)`,
                                    "&:hover": {
                                      boxShadow: `0px 0px 60px rgba(0, 0, 0, 0.2)`,
                                    },
                                  }}
                                >
                                  <a
                                    onClick={() => openModal(e?.id)}
                                    sx={{
                                      cursor: `pointer`,
                                    }}
                                  >
                                    {e?.featuredImage !== null || "" ? (
                                      <Img
                                        sx={{ margin: `5px` }}
                                        fluid={
                                          e.featuredImage?.node?.localFile
                                            ?.childImageSharp?.fluid
                                        }
                                      />
                                    ) : (
                                      <img
                                        src={SponorPlaceholder}
                                        alt="Sponsor Placeholder"
                                        sx={{ margin: `5px` }}
                                      />
                                    )}
                                  </a>
                                  <Modal
                                    isOpen={modalIsOpen === e?.id}
                                    onRequestClose={closeModal}
                                    closeTimeoutMS={500}
                                    style={customStyles}
                                    ariaHideApp={false}
                                    contentLabel={e?.id}
                                  >
                                    <div sx={{ pt: `2rem` }}>
                                      <FaTimes
                                        onClick={closeModal}
                                        style={{
                                          position: `absolute`,
                                          right: `15px`,
                                          top: `15px`,
                                          cursor: `pointer`,
                                        }}
                                      />
                                      <div
                                        className="grid-container"
                                        onClick={closeModal}
                                      >
                                        <div
                                          sx={{
                                            display: `grid`,
                                            gridTemplateColumns: [
                                              "12fr",
                                              "12fr",
                                              "4fr 8fr",
                                            ],
                                            gridTemplateRows: `repeat(auto)`,
                                            rowGap: `30px`,
                                            gridColumnGap: `3%`,
                                          }}
                                          key={e.id}
                                          className="sponsors-one"
                                        >
                                          <div>
                                            <div>
                                              <a
                                                href={
                                                  e.sponsorDetails?.sponsorLink
                                                }
                                                onClick="#"
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {e?.featuredImage !== null ||
                                                "" ? (
                                                  <Img
                                                    sx={{
                                                      maxWidth: [
                                                        "60%",
                                                        "50%",
                                                        "70%",
                                                      ],
                                                      mb: `3rem`,
                                                    }}
                                                    fluid={
                                                      e.featuredImage?.node
                                                        ?.localFile
                                                        ?.childImageSharp?.fluid
                                                    }
                                                  />
                                                ) : (
                                                  <img
                                                    src={SponorPlaceholder}
                                                    alt="Sponsor Placeholder"
                                                    sx={{
                                                      maxWidth: [
                                                        "60%",
                                                        "50%",
                                                        "70%",
                                                      ],
                                                      mb: `3rem`,
                                                    }}
                                                  />
                                                )}
                                              </a>
                                            </div>
                                            {e?.sponsorDetails?.contactInfo ? (
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    e?.sponsorDetails
                                                      ?.contactInfo,
                                                }}
                                                sx={{ mb: `10px` }}
                                              ></div>
                                            ) : null}
                                            {e?.sponsorDetails?.contactEmail !=
                                            null ? (
                                              <div onClick="#">
                                                <Obfuscate
                                                  href={`mailto:${e?.sponsorDetails?.contactEmail}`}
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  <button
                                                    sx={{
                                                      width: `200px`,
                                                      mb: `1rem`,
                                                    }}
                                                  >
                                                    {
                                                      e?.sponsorDetails
                                                        ?.emailButtonLabel
                                                    }
                                                  </button>
                                                </Obfuscate>
                                              </div>
                                            ) : null}
                                            {e?.sponsorDetails?.contactUrl !=
                                            null ? (
                                              <div onClick="#">
                                                <a
                                                  href={
                                                    e?.sponsorDetails
                                                      ?.contactUrl
                                                  }
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  <button
                                                    sx={{ width: `200px` }}
                                                  >
                                                    {
                                                      e?.sponsorDetails
                                                        ?.urlButtonLabel
                                                    }
                                                  </button>
                                                </a>
                                              </div>
                                            ) : null}
                                          </div>
                                          <div sx={{ maxWidth: `96%` }}>
                                            <div>
                                              {e?.sponsorDetails?.upperImage ||
                                              e?.sponsorDetails?.upperVideo !=
                                                null ? (
                                                <div>
                                                  {e?.sponsorDetails
                                                    ?.upperMediaFileType ===
                                                  "Image" ? (
                                                    <Img
                                                      fluid={
                                                        e?.sponsorDetails
                                                          ?.upperImage
                                                          ?.localFile
                                                          ?.childImageSharp
                                                          ?.fluid
                                                      }
                                                      sx={{ mb: `50px` }}
                                                    />
                                                  ) : null}{" "}
                                                  {e?.sponsorDetails
                                                    ?.upperMediaFileType ===
                                                  "Video" ? (
                                                    <iframe
                                                      width="100%"
                                                      height="450"
                                                      src={
                                                        e?.sponsorDetails
                                                          ?.upperVideo
                                                      }
                                                      frameBorder="0"
                                                      title="video"
                                                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                      allowFullScreen
                                                      loading="lazy"
                                                    ></iframe>
                                                  ) : null}
                                                </div>
                                              ) : null}
                                            </div>
                                            {e?.sponsorDetails
                                              ?.sponsorDescription ? (
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    e?.sponsorDetails
                                                      ?.sponsorDescription,
                                                }}
                                                sx={{ mb: `50px` }}
                                              ></div>
                                            ) : null}
                                            {e?.sponsorDetails?.lowerImage ||
                                            e?.sponsorDetails
                                              ?.lowerVideoCopy ? (
                                              <div>
                                                {e?.sponsorDetails
                                                  ?.lowerMediaFileType ===
                                                "Image" ? (
                                                  <Img
                                                    fluid={
                                                      e?.sponsorDetails
                                                        ?.lowerImage?.localFile
                                                        ?.childImageSharp?.fluid
                                                    }
                                                    sx={{ mb: `50px` }}
                                                  />
                                                ) : null}{" "}
                                                {e?.sponsorDetails
                                                  ?.lowerMediaFileType ===
                                                "Video" ? (
                                                  <iframe
                                                    width="100%"
                                                    height="450"
                                                    src={
                                                      e?.sponsorDetails
                                                        ?.lowerVideoCopy
                                                    }
                                                    frameBorder="0"
                                                    title="video"
                                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                    loading="lazy"
                                                  ></iframe>
                                                ) : null}
                                              </div>
                                            ) : null}
                                            {e.sponsorDetails
                                              .sponsorDescription ||
                                            e.sponsorDetails.lowerImage ||
                                            e.sponsorDetails.lowerVideoCopy !=
                                              null ? (
                                              <div
                                                sx={{
                                                  position: `absolute`,
                                                  right: `0`,
                                                  top: `50%`,
                                                }}
                                              >
                                                <CgScrollV size={40} />
                                                <p
                                                  sx={{
                                                    margin: `7px 0px 0px -3px`,
                                                    transform: `rotate(90deg)`,
                                                    fontWeight: `bold`,
                                                  }}
                                                >
                                                  Scroll
                                                </p>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Modal>
                                </div>
                              )
                            })}
                          </div>
                        )
                      })}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          ) : null}
          {sponsors.simpleEvents.ranks[4] ? (
            <div>
              {sponsors.simpleEvents.ranks[4].sponsor?.length > 0 ? (
                <div className="gap-xxl">
                  {stateTalks ? (
                    <div>
                      <h3>
                        {sponsors?.simpleEvents?.sponsorLevelTitles?.levelFive}
                      </h3>
                      {talks?.map((f, index) => {
                        return (
                          <div
                            key={index}
                            sx={{
                              display: `grid`,
                              gridTemplateColumns: [
                                `repeat(1, 1fr)`,
                                `repeat(2, 1fr)`,
                                `repeat(5, 1fr)`,
                              ],
                              gridTemplateRows: `repeat(auto)`,
                              width: `100%`,
                              gridColumnGap: `1rem`,
                              flexWrap: `wrap`,
                              rowGap: `30px`,
                              alignItems: `center`,
                            }}
                          >
                            {f.sponsor?.map(e => {
                              return (
                                <div
                                  key={e.id}
                                  className="sponsors-one"
                                  sx={{
                                    display: `flex`,
                                    position: `relative`,
                                    background: `#fff`,
                                    flexDirection: `column`,
                                    justifyContent: `center`,
                                    padding: `1rem`,
                                    textAlign: `center`,
                                    textAlign: `-webkit-center`,
                                    transition: `300ms`,
                                    height: `100%`,
                                    boxShadow: `0px 0px 60px rgba(0, 0, 0, 0.08)`,
                                    "&:hover": {
                                      boxShadow: `0px 0px 60px rgba(0, 0, 0, 0.2)`,
                                    },
                                  }}
                                >
                                  <a
                                    onClick={() => openModal(e?.id)}
                                    sx={{
                                      cursor: `pointer`,
                                    }}
                                  >
                                    {e?.featuredImage !== null || "" ? (
                                      <Img
                                        sx={{ margin: `5px` }}
                                        fluid={
                                          e.featuredImage?.node?.localFile
                                            ?.childImageSharp?.fluid
                                        }
                                      />
                                    ) : (
                                      <img
                                        src={SponorPlaceholder}
                                        alt="Sponsor Placeholder"
                                        sx={{ margin: `5px` }}
                                      />
                                    )}
                                  </a>
                                  <Modal
                                    isOpen={modalIsOpen === e?.id}
                                    onRequestClose={closeModal}
                                    closeTimeoutMS={500}
                                    style={customStyles}
                                    ariaHideApp={false}
                                    contentLabel={e?.id}
                                  >
                                    <div sx={{ pt: `2rem` }}>
                                      <FaTimes
                                        onClick={closeModal}
                                        style={{
                                          position: `absolute`,
                                          right: `15px`,
                                          top: `15px`,
                                          cursor: `pointer`,
                                        }}
                                      />
                                      <div
                                        className="grid-container"
                                        onClick={closeModal}
                                      >
                                        <div
                                          sx={{
                                            display: `grid`,
                                            gridTemplateColumns: [
                                              "12fr",
                                              "12fr",
                                              "4fr 8fr",
                                            ],
                                            gridTemplateRows: `repeat(auto)`,
                                            rowGap: `30px`,
                                            gridColumnGap: `3%`,
                                          }}
                                          key={e.id}
                                          className="sponsors-one"
                                        >
                                          <div>
                                            <div>
                                              <a
                                                href={
                                                  e.sponsorDetails?.sponsorLink
                                                }
                                                onClick="#"
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {e?.featuredImage !== null ||
                                                "" ? (
                                                  <Img
                                                    sx={{
                                                      maxWidth: [
                                                        "60%",
                                                        "50%",
                                                        "70%",
                                                      ],
                                                      mb: `3rem`,
                                                    }}
                                                    fluid={
                                                      e.featuredImage?.node
                                                        ?.localFile
                                                        ?.childImageSharp?.fluid
                                                    }
                                                  />
                                                ) : (
                                                  <img
                                                    src={SponorPlaceholder}
                                                    alt="Sponsor Placeholder"
                                                    sx={{
                                                      maxWidth: [
                                                        "60%",
                                                        "50%",
                                                        "70%",
                                                      ],
                                                      mb: `3rem`,
                                                    }}
                                                  />
                                                )}
                                              </a>
                                            </div>
                                            {e?.sponsorDetails?.contactInfo ? (
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    e?.sponsorDetails
                                                      ?.contactInfo,
                                                }}
                                                sx={{ mb: `10px` }}
                                              ></div>
                                            ) : null}
                                            {e?.sponsorDetails?.contactEmail !=
                                            null ? (
                                              <div onClick="#">
                                                <Obfuscate
                                                  href={`mailto:${e?.sponsorDetails?.contactEmail}`}
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  <button
                                                    sx={{
                                                      width: `200px`,
                                                      mb: `1rem`,
                                                    }}
                                                  >
                                                    {
                                                      e?.sponsorDetails
                                                        ?.emailButtonLabel
                                                    }
                                                  </button>
                                                </Obfuscate>
                                              </div>
                                            ) : null}
                                            {e?.sponsorDetails?.contactUrl !=
                                            null ? (
                                              <div onClick="#">
                                                <a
                                                  href={
                                                    e?.sponsorDetails
                                                      ?.contactUrl
                                                  }
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  <button
                                                    sx={{ width: `200px` }}
                                                  >
                                                    {
                                                      e?.sponsorDetails
                                                        ?.urlButtonLabel
                                                    }
                                                  </button>
                                                </a>
                                              </div>
                                            ) : null}
                                          </div>
                                          <div sx={{ maxWidth: `96%` }}>
                                            <div>
                                              {e?.sponsorDetails?.upperImage ||
                                              e?.sponsorDetails?.upperVideo !=
                                                null ? (
                                                <div>
                                                  {e?.sponsorDetails
                                                    ?.upperMediaFileType ===
                                                  "Image" ? (
                                                    <Img
                                                      fluid={
                                                        e?.sponsorDetails
                                                          ?.upperImage
                                                          ?.localFile
                                                          ?.childImageSharp
                                                          ?.fluid
                                                      }
                                                      sx={{ mb: `50px` }}
                                                    />
                                                  ) : null}{" "}
                                                  {e?.sponsorDetails
                                                    ?.upperMediaFileType ===
                                                  "Video" ? (
                                                    <iframe
                                                      width="100%"
                                                      height="450"
                                                      src={
                                                        e?.sponsorDetails
                                                          ?.upperVideo
                                                      }
                                                      frameBorder="0"
                                                      title="video"
                                                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                      allowFullScreen
                                                      loading="lazy"
                                                    ></iframe>
                                                  ) : null}
                                                </div>
                                              ) : null}
                                            </div>
                                            {e?.sponsorDetails
                                              ?.sponsorDescription ? (
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    e?.sponsorDetails
                                                      ?.sponsorDescription,
                                                }}
                                                sx={{ mb: `50px` }}
                                              ></div>
                                            ) : null}
                                            {e?.sponsorDetails?.lowerImage ||
                                            e?.sponsorDetails
                                              ?.lowerVideoCopy ? (
                                              <div>
                                                {e?.sponsorDetails
                                                  ?.lowerMediaFileType ===
                                                "Image" ? (
                                                  <Img
                                                    fluid={
                                                      e?.sponsorDetails
                                                        ?.lowerImage?.localFile
                                                        ?.childImageSharp?.fluid
                                                    }
                                                    sx={{ mb: `50px` }}
                                                  />
                                                ) : null}{" "}
                                                {e?.sponsorDetails
                                                  ?.lowerMediaFileType ===
                                                "Video" ? (
                                                  <iframe
                                                    width="100%"
                                                    height="450"
                                                    src={
                                                      e?.sponsorDetails
                                                        ?.lowerVideoCopy
                                                    }
                                                    frameBorder="0"
                                                    title="video"
                                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                    loading="lazy"
                                                  ></iframe>
                                                ) : null}
                                              </div>
                                            ) : null}
                                            {e.sponsorDetails
                                              .sponsorDescription ||
                                            e.sponsorDetails.lowerImage ||
                                            e.sponsorDetails.lowerVideoCopy !=
                                              null ? (
                                              <div
                                                sx={{
                                                  position: `absolute`,
                                                  right: `0`,
                                                  top: `50%`,
                                                }}
                                              >
                                                <CgScrollV size={40} />
                                                <p
                                                  sx={{
                                                    margin: `7px 0px 0px -3px`,
                                                    transform: `rotate(90deg)`,
                                                    fontWeight: `bold`,
                                                  }}
                                                >
                                                  Scroll
                                                </p>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Modal>
                                </div>
                              )
                            })}
                          </div>
                        )
                      })}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          ) : null}
          {sponsors.simpleEvents.ranks[5] ? (
            <div>
              {sponsors.simpleEvents.ranks[5].sponsor?.length > 0 ? (
                <div className="gap-xxl">
                  {stateSix ? (
                    <div>
                      <h3>
                        {sponsors?.simpleEvents?.sponsorLevelTitles?.levelSix}
                      </h3>
                      {six?.map((f, index) => {
                        return (
                          <div
                            key={index}
                            sx={{
                              display: `grid`,
                              gridTemplateColumns: [
                                `repeat(1, 1fr)`,
                                `repeat(2, 1fr)`,
                                `repeat(5, 1fr)`,
                              ],
                              gridTemplateRows: `repeat(auto)`,
                              width: `100%`,
                              gridColumnGap: `1rem`,
                              flexWrap: `wrap`,
                              rowGap: `30px`,
                              alignItems: `center`,
                            }}
                          >
                            {f.sponsor?.map(e => {
                              return (
                                <div
                                  key={e.id}
                                  className="sponsors-one"
                                  sx={{
                                    display: `flex`,
                                    position: `relative`,
                                    background: `#fff`,
                                    flexDirection: `column`,
                                    justifyContent: `center`,
                                    padding: `1rem`,
                                    textAlign: `center`,
                                    textAlign: `-webkit-center`,
                                    transition: `300ms`,
                                    height: `100%`,
                                    boxShadow: `0px 0px 60px rgba(0, 0, 0, 0.08)`,
                                    "&:hover": {
                                      boxShadow: `0px 0px 60px rgba(0, 0, 0, 0.2)`,
                                    },
                                  }}
                                >
                                  <a
                                    onClick={() => openModal(e?.id)}
                                    sx={{
                                      cursor: `pointer`,
                                    }}
                                  >
                                    {e?.featuredImage !== null || "" ? (
                                      <Img
                                        sx={{ margin: `5px` }}
                                        fluid={
                                          e.featuredImage?.node?.localFile
                                            ?.childImageSharp?.fluid
                                        }
                                      />
                                    ) : (
                                      <img
                                        src={SponorPlaceholder}
                                        alt="Sponsor Placeholder"
                                        sx={{ margin: `5px` }}
                                      />
                                    )}
                                  </a>
                                  <Modal
                                    isOpen={modalIsOpen === e?.id}
                                    onRequestClose={closeModal}
                                    closeTimeoutMS={500}
                                    style={customStyles}
                                    ariaHideApp={false}
                                    contentLabel={e?.id}
                                  >
                                    <div sx={{ pt: `2rem` }}>
                                      <FaTimes
                                        onClick={closeModal}
                                        style={{
                                          position: `absolute`,
                                          right: `15px`,
                                          top: `15px`,
                                          cursor: `pointer`,
                                        }}
                                      />
                                      <div
                                        className="grid-container"
                                        onClick={closeModal}
                                      >
                                        <div
                                          sx={{
                                            display: `grid`,
                                            gridTemplateColumns: [
                                              "12fr",
                                              "12fr",
                                              "4fr 8fr",
                                            ],
                                            gridTemplateRows: `repeat(auto)`,
                                            rowGap: `30px`,
                                            gridColumnGap: `3%`,
                                          }}
                                          key={e.id}
                                          className="sponsors-one"
                                        >
                                          <div>
                                            <div>
                                              <a
                                                href={
                                                  e.sponsorDetails?.sponsorLink
                                                }
                                                onClick="#"
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {e?.featuredImage !== null ||
                                                "" ? (
                                                  <Img
                                                    sx={{
                                                      maxWidth: [
                                                        "60%",
                                                        "50%",
                                                        "70%",
                                                      ],
                                                      mb: `3rem`,
                                                    }}
                                                    fluid={
                                                      e.featuredImage?.node
                                                        ?.localFile
                                                        ?.childImageSharp?.fluid
                                                    }
                                                  />
                                                ) : (
                                                  <img
                                                    src={SponorPlaceholder}
                                                    alt="Sponsor Placeholder"
                                                    sx={{
                                                      maxWidth: [
                                                        "60%",
                                                        "50%",
                                                        "70%",
                                                      ],
                                                      mb: `3rem`,
                                                    }}
                                                  />
                                                )}
                                              </a>
                                            </div>
                                            {e?.sponsorDetails?.contactInfo ? (
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    e?.sponsorDetails
                                                      ?.contactInfo,
                                                }}
                                                sx={{ mb: `10px` }}
                                              ></div>
                                            ) : null}
                                            {e?.sponsorDetails?.contactEmail !=
                                            null ? (
                                              <div onClick="#">
                                                <Obfuscate
                                                  href={`mailto:${e?.sponsorDetails?.contactEmail}`}
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  <button
                                                    sx={{
                                                      width: `200px`,
                                                      mb: `1rem`,
                                                    }}
                                                  >
                                                    {
                                                      e?.sponsorDetails
                                                        ?.emailButtonLabel
                                                    }
                                                  </button>
                                                </Obfuscate>
                                              </div>
                                            ) : null}
                                            {e?.sponsorDetails?.contactUrl !=
                                            null ? (
                                              <div onClick="#">
                                                <a
                                                  href={
                                                    e?.sponsorDetails
                                                      ?.contactUrl
                                                  }
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  <button
                                                    sx={{ width: `200px` }}
                                                  >
                                                    {
                                                      e?.sponsorDetails
                                                        ?.urlButtonLabel
                                                    }
                                                  </button>
                                                </a>
                                              </div>
                                            ) : null}
                                          </div>
                                          <div sx={{ maxWidth: `96%` }}>
                                            <div>
                                              {e?.sponsorDetails?.upperImage ||
                                              e?.sponsorDetails?.upperVideo !=
                                                null ? (
                                                <div>
                                                  {e?.sponsorDetails
                                                    ?.upperMediaFileType ===
                                                  "Image" ? (
                                                    <Img
                                                      fluid={
                                                        e?.sponsorDetails
                                                          ?.upperImage
                                                          ?.localFile
                                                          ?.childImageSharp
                                                          ?.fluid
                                                      }
                                                      sx={{ mb: `50px` }}
                                                    />
                                                  ) : null}{" "}
                                                  {e?.sponsorDetails
                                                    ?.upperMediaFileType ===
                                                  "Video" ? (
                                                    <iframe
                                                      width="100%"
                                                      height="450"
                                                      src={
                                                        e?.sponsorDetails
                                                          ?.upperVideo
                                                      }
                                                      frameBorder="0"
                                                      title="video"
                                                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                      allowFullScreen
                                                      loading="lazy"
                                                    ></iframe>
                                                  ) : null}
                                                </div>
                                              ) : null}
                                            </div>
                                            {e?.sponsorDetails
                                              ?.sponsorDescription ? (
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    e?.sponsorDetails
                                                      ?.sponsorDescription,
                                                }}
                                                sx={{ mb: `50px` }}
                                              ></div>
                                            ) : null}
                                            {e?.sponsorDetails?.lowerImage ||
                                            e?.sponsorDetails
                                              ?.lowerVideoCopy ? (
                                              <div>
                                                {e?.sponsorDetails
                                                  ?.lowerMediaFileType ===
                                                "Image" ? (
                                                  <Img
                                                    fluid={
                                                      e?.sponsorDetails
                                                        ?.lowerImage?.localFile
                                                        ?.childImageSharp?.fluid
                                                    }
                                                    sx={{ mb: `50px` }}
                                                  />
                                                ) : null}{" "}
                                                {e?.sponsorDetails
                                                  ?.lowerMediaFileType ===
                                                "Video" ? (
                                                  <iframe
                                                    width="100%"
                                                    height="450"
                                                    src={
                                                      e?.sponsorDetails
                                                        ?.lowerVideoCopy
                                                    }
                                                    frameBorder="0"
                                                    title="video"
                                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                    loading="lazy"
                                                  ></iframe>
                                                ) : null}
                                              </div>
                                            ) : null}
                                            {e.sponsorDetails
                                              .sponsorDescription ||
                                            e.sponsorDetails.lowerImage ||
                                            e.sponsorDetails.lowerVideoCopy !=
                                              null ? (
                                              <div
                                                sx={{
                                                  position: `absolute`,
                                                  right: `0`,
                                                  top: `50%`,
                                                }}
                                              >
                                                <CgScrollV size={40} />
                                                <p
                                                  sx={{
                                                    margin: `7px 0px 0px -3px`,
                                                    transform: `rotate(90deg)`,
                                                    fontWeight: `bold`,
                                                  }}
                                                >
                                                  Scroll
                                                </p>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Modal>
                                </div>
                              )
                            })}
                          </div>
                        )
                      })}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  )
}

export default Sponsors

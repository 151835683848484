import React, { useState, useRef, useEffect } from "react"
import Chevron from "./ChevronIcon"

import "./_accordion.scss"

const AccordionModule = props => {
  const [active, setActive] = useState(true)
  const contentRef = useRef(null)

  useEffect(() => {
    contentRef.current.style.maxHeight = active
      ? `${contentRef.current.scrollHeight}rem`
      : "0rem"
  }, [contentRef, active])

  const toogleActive = () => {
    setActive(!active)
  }

  const titleStyle = {
    fontWeight: 600,
    fontSize: "20px",
  }

  return (
    <div>
      <button className="accordion" onClick={toogleActive}>
        <p className="accordion__title" style={titleStyle}>
          {props?.title}
        </p>
        <Chevron
          className={active ? "accordion__icon rotate" : "accordion__icon"}
          width={10}
          fill={"#777"}
        />
      </button>

      <div ref={contentRef} className="accordion__content">
        <div className="accordion__inner-content">{props?.children}</div>
      </div>
    </div>
  )
}

export default AccordionModule

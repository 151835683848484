import React, { useState, useRef, useEffect } from "react"
import Chevron from "./ChevronIcon"

import "./_accordion.scss"

const AccordionModuleCollapsed = props => {
  //console.log(props)

  const [activeCollapse, setActiveCollapse] = useState(false)
  const contentSRef = useRef()

  useEffect(() => {
    contentSRef.current.style.maxHeight = activeCollapse
      ? `${contentSRef.current.scrollHeight}rem`
      : "0rem"
  }, [contentSRef, activeCollapse])

  const toogleActiveCollapse = () => {
    setActiveCollapse(!activeCollapse)
  }

  const titleStyle = {
    fontWeight: 600,
    fontSize: "20px",
  }

  return (
    <div>
      <div>
        <button className="accordion" onClick={toogleActiveCollapse}>
          <p className="accordion__title" style={titleStyle}>
            {props?.title}
          </p>
          <Chevron
            className={
              activeCollapse ? "accordion__icon rotate" : "accordion__icon"
            }
            width={10}
            fill={"#777"}
          />
        </button>

        <div ref={contentSRef} className="accordion__content">
          <div className="accordion__inner-content">{props?.children}</div>
        </div>
      </div>
    </div>
  )
}

export default AccordionModuleCollapsed

/** @jsx jsx */
import { jsx } from "theme-ui"
import Img from "gatsby-image"
import AccordionModule from "../Events/AccordionModule"
import AccordionModuleCollapsed from "../Events/AccordionModuleCollapsed"
import { FaClock, FaFileDownload, FaInfo } from "react-icons/fa"
import BiliBili from "../../images/bilibili.png"
import YouTube from "../../images/youtube.svg"
import Placeholder from "../../images/person_placeholder.jpg"

const Accordion = props => {
  return (
    <div id="schedule">
      {props.accordion.simpleEvents.timeSlots ? (
        <div className="grid-container gap-xxl">
          <h2>Schedule</h2>
          {props.accordion.simpleEvents.timeSlots.map((e, index) => {
            return (
              <div key={index}>
                {props.accordion.simpleEvents.collapsedSimpleEventSchedule ===
                  null || false ? (
                  <AccordionModule title={`${e?.startTime} to ${e?.endTime}`}>
                    <div sx={{ mt: `0px`, mb: `15px` }}>
                      <div>
                        {e.timeZone ? (
                          <p sx={{ marginBottom: `5px` }}>
                            Timezone: {e.timeZone}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    {e?.presentations?.map((pre, index) => {
                      return (
                        <div className="accordion-text">
                          <div>
                            <div
                              key={index}
                              className="grid-x grid-margin-x grid-margin-y"
                              sx={{ position: `relative` }}
                            >
                              <div className="large-12 medium-12 small-12 cell">
                                <div
                                  sx={{
                                    border: `1px solid rgba(0,0,0, .2)`,
                                    borderRadius: `10px`,
                                    p: `10px`,
                                    mb: `15px`,
                                  }}
                                >
                                  <div className="schedule__title gap-m">
                                    <h4>{pre?.title}</h4>
                                  </div>
                                  <div className="gap-l">
                                    {pre?.presentation?.presentationSpeaker ? (
                                      <div className="gap-m">
                                        {pre?.presentation?.presentationSpeaker?.map(
                                          theSpeaker => {
                                            return (
                                              <div>
                                                <p className="schedule__speaker">
                                                  {
                                                    theSpeaker?.person
                                                      ?.personName
                                                  }
                                                  ,{" "}
                                                  {theSpeaker?.person?.jobTitle}
                                                </p>
                                                <p className="schedule__speaker">
                                                  {theSpeaker?.person?.company}
                                                  {theSpeaker?.person
                                                    ?.alternativeCompany ? (
                                                    <>
                                                      ,
                                                      {
                                                        theSpeaker?.person
                                                          ?.alternativeCompany
                                                      }
                                                    </>
                                                  ) : null}
                                                </p>
                                                <h4>
                                                  {
                                                    pre?.presentation
                                                      ?.alternativeTitleChinese
                                                  }
                                                </h4>
                                                {pre?.presentation
                                                  ?.shortDescription ? (
                                                  <div>
                                                    <div
                                                      className="gap-l accordion-abstract"
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          pre?.presentation
                                                            ?.shortDescription,
                                                      }}
                                                    ></div>
                                                  </div>
                                                ) : null}
                                                {pre?.presentation
                                                  ?.shortDescriptionCn ? (
                                                  <div>
                                                    <div
                                                      className="gap-l accordion-abstract"
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          pre?.presentation
                                                            ?.shortDescriptionCn,
                                                      }}
                                                    ></div>
                                                  </div>
                                                ) : null}
                                              </div>
                                            )
                                          }
                                        )}
                                      </div>
                                    ) : null}
                                    <div>
                                      {pre?.presentation
                                        ?.presentationSpeaker ? (
                                        <div className="gap-m">
                                          {pre?.presentation?.presentationSpeaker?.map(
                                            theSpeaker => {
                                              return (
                                                <div>
                                                  <div
                                                    className="grid-x grid-margin-x grid-margin-y"
                                                    key={theSpeaker?.id}
                                                    sx={{
                                                      marginBottom: `5px`,
                                                    }}
                                                  >
                                                    <div className="large-3 medium-4 cell">
                                                      {theSpeaker?.person
                                                        ?.personImage?.localFile
                                                        ?.childImageSharp
                                                        ?.fluid ? (
                                                        <Img
                                                          sx={{
                                                            margin: `5px`,
                                                          }}
                                                          fluid={
                                                            theSpeaker?.person
                                                              ?.personImage
                                                              ?.localFile
                                                              ?.childImageSharp
                                                              ?.fluid
                                                          }
                                                        />
                                                      ) : (
                                                        <img
                                                          src={Placeholder}
                                                        />
                                                      )}
                                                    </div>
                                                    <div className="large-9 medium-8 cell">
                                                      <p className="schedule__speaker">
                                                        {
                                                          theSpeaker?.person
                                                            ?.personName
                                                        }
                                                        ,{" "}
                                                        {
                                                          theSpeaker?.person
                                                            ?.jobTitle
                                                        }
                                                      </p>
                                                      <p className="schedule__speaker">
                                                        {
                                                          theSpeaker?.person
                                                            ?.company
                                                        }
                                                        {theSpeaker?.person
                                                          ?.alternativeCompany ? (
                                                          <>
                                                            ,
                                                            {
                                                              theSpeaker?.person
                                                                ?.alternativeCompany
                                                            }
                                                          </>
                                                        ) : null}
                                                      </p>
                                                      <div
                                                        className="gap-l accordion-abstract"
                                                        dangerouslySetInnerHTML={{
                                                          __html:
                                                            theSpeaker?.person
                                                              ?.bio,
                                                        }}
                                                      ></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            }
                                          )}
                                        </div>
                                      ) : null}
                                      {pre?.presentation?.longDescription ? (
                                        <details className="gap-m">
                                          <summary>
                                            <b>Abstract (English)</b>
                                          </summary>
                                          <div
                                            className="gap-l accordion-abstract"
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                pre?.presentation
                                                  ?.longDescription,
                                            }}
                                          ></div>
                                        </details>
                                      ) : null}
                                      {pre?.presentation?.longDescriptionCn ? (
                                        <details className="gap-l">
                                          <summary>
                                            <b>摘要 (Chinese)</b>
                                          </summary>
                                          <div
                                            className="gap-l accordion-abstract"
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                pre?.presentation
                                                  ?.longDescriptionCn,
                                            }}
                                          ></div>
                                        </details>
                                      ) : null}

                                      {pre?.presentation?.presentationUrl ? (
                                        <div
                                          sx={{
                                            marginBottom: `5px`,
                                          }}
                                        >
                                          <a
                                            href={
                                              pre?.presentation?.presentationUrl
                                            }
                                            target="__blank"
                                            rel="noreferrer"
                                          >
                                            <FaInfo
                                              size="18"
                                              color="#3a5d7f"
                                              sx={{
                                                marginRight: `10px`,
                                                mb: `-1px!important`,
                                              }}
                                            />
                                            More about this presentation
                                          </a>
                                        </div>
                                      ) : null}
                                      {pre?.presentation?.files ? (
                                        <ul
                                          sx={{
                                            listStyle: `none`,
                                            margin: `0`,
                                          }}
                                        >
                                          <li
                                            sx={{
                                              paddingRight: `20px`,
                                            }}
                                          >
                                            {pre?.presentation?.files?.map(
                                              (fileMap, index) => {
                                                return (
                                                  <div key={index}>
                                                    {fileMap.file ? (
                                                      <a
                                                        href={
                                                          fileMap.file
                                                            .mediaItemUrl
                                                        }
                                                        target="__blank"
                                                      >
                                                        <FaFileDownload
                                                          size="18"
                                                          color="#3a5d7f"
                                                          sx={{
                                                            marginRight: `10px`,
                                                            mb: `-3px!important`,
                                                          }}
                                                        />
                                                        {fileMap.file.title}
                                                      </a>
                                                    ) : null}
                                                  </div>
                                                )
                                              }
                                            )}
                                          </li>
                                        </ul>
                                      ) : null}
                                      {pre.presentation.youtube ||
                                      pre.presentation.bilibili ? (
                                        <ul
                                          sx={{
                                            listStyle: `none`,
                                            margin: `0`,
                                            display: `flex`,
                                          }}
                                        >
                                          <li
                                            sx={{
                                              paddingRight: `20px`,
                                            }}
                                          >
                                            {pre.presentation.youtube ? (
                                              <a
                                                href={
                                                  pre?.presentation?.youtube
                                                }
                                                target="__blank"
                                              >
                                                <img
                                                  src={YouTube}
                                                  alt="YouTube"
                                                  sx={{
                                                    width: `25px`,
                                                  }}
                                                ></img>
                                              </a>
                                            ) : null}
                                          </li>
                                          <li>
                                            {pre.presentation.bilibili ? (
                                              <a
                                                href={
                                                  pre?.presentation?.bilibili
                                                }
                                                target="__blank"
                                              >
                                                <img
                                                  src={BiliBili}
                                                  alt="BiliBili"
                                                  sx={{
                                                    width: `65px`,
                                                  }}
                                                ></img>
                                              </a>
                                            ) : null}
                                          </li>
                                        </ul>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </AccordionModule>
                ) : (
                  <AccordionModuleCollapsed
                    title={`${e?.startTime} to ${e?.endTime}`}
                  >
                    <div sx={{ mt: `0px`, mb: `15px` }}>
                      <div>
                        {e.timeZone ? (
                          <p sx={{ marginBottom: `5px` }}>
                            Timezone: {e.timeZone}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    {e?.presentations?.map((pre, index) => {
                      return (
                        <div className="accordion-text">
                          <div>
                            <div
                              key={index}
                              className="grid-x grid-margin-x grid-margin-y"
                              sx={{ position: `relative` }}
                            >
                              <div className="large-12 medium-12 small-12 cell">
                                <div
                                  sx={{
                                    border: `1px solid rgba(0,0,0, .2)`,
                                    borderRadius: `10px`,
                                    p: `10px`,
                                    mb: `15px`,
                                  }}
                                >
                                  <div className="schedule__title gap-m">
                                    <h4>{pre?.title}</h4>
                                  </div>
                                  <div className="gap-l">
                                    {pre?.presentation?.presentationSpeaker ? (
                                      <div className="gap-m">
                                        {pre?.presentation?.presentationSpeaker?.map(
                                          theSpeaker => {
                                            return (
                                              <div>
                                                <p className="schedule__speaker">
                                                  {
                                                    theSpeaker?.person
                                                      ?.personName
                                                  }
                                                  ,{" "}
                                                  {theSpeaker?.person?.jobTitle}
                                                </p>
                                                <p className="schedule__speaker">
                                                  {theSpeaker?.person?.company}
                                                  {theSpeaker?.person
                                                    ?.alternativeCompany ? (
                                                    <>
                                                      ,
                                                      {
                                                        theSpeaker?.person
                                                          ?.alternativeCompany
                                                      }
                                                    </>
                                                  ) : null}
                                                </p>
                                                <h4>
                                                  {
                                                    pre?.presentation
                                                      ?.alternativeTitleChinese
                                                  }
                                                </h4>
                                                {pre?.presentation
                                                  ?.shortDescription ? (
                                                  <div>
                                                    <div
                                                      className="gap-l accordion-abstract"
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          pre?.presentation
                                                            ?.shortDescription,
                                                      }}
                                                    ></div>
                                                  </div>
                                                ) : null}
                                                {pre?.presentation
                                                  ?.shortDescriptionCn ? (
                                                  <div>
                                                    <div
                                                      className="gap-l accordion-abstract"
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          pre?.presentation
                                                            ?.shortDescriptionCn,
                                                      }}
                                                    ></div>
                                                  </div>
                                                ) : null}
                                              </div>
                                            )
                                          }
                                        )}
                                      </div>
                                    ) : null}
                                    <div>
                                      {pre?.presentation
                                        ?.presentationSpeaker ? (
                                        <div className="gap-m">
                                          {pre?.presentation?.presentationSpeaker?.map(
                                            theSpeaker => {
                                              return (
                                                <div>
                                                  <div
                                                    className="grid-x grid-margin-x grid-margin-y"
                                                    key={theSpeaker?.id}
                                                    sx={{
                                                      marginBottom: `5px`,
                                                    }}
                                                  >
                                                    <div className="large-3 medium-4 cell">
                                                      {theSpeaker?.person
                                                        ?.personImage?.localFile
                                                        ?.childImageSharp
                                                        ?.fluid ? (
                                                        <Img
                                                          sx={{
                                                            margin: `5px`,
                                                          }}
                                                          fluid={
                                                            theSpeaker?.person
                                                              ?.personImage
                                                              ?.localFile
                                                              ?.childImageSharp
                                                              ?.fluid
                                                          }
                                                        />
                                                      ) : (
                                                        <img
                                                          src={Placeholder}
                                                        />
                                                      )}
                                                    </div>
                                                    <div className="large-9 medium-8 cell">
                                                      <p className="schedule__speaker">
                                                        {
                                                          theSpeaker?.person
                                                            ?.personName
                                                        }
                                                        ,{" "}
                                                        {
                                                          theSpeaker?.person
                                                            ?.jobTitle
                                                        }
                                                      </p>
                                                      <p className="schedule__speaker">
                                                        {
                                                          theSpeaker?.person
                                                            ?.company
                                                        }
                                                        {theSpeaker?.person
                                                          ?.alternativeCompany ? (
                                                          <>
                                                            ,
                                                            {
                                                              theSpeaker?.person
                                                                ?.alternativeCompany
                                                            }
                                                          </>
                                                        ) : null}
                                                      </p>
                                                      <div
                                                        className="gap-l accordion-abstract"
                                                        dangerouslySetInnerHTML={{
                                                          __html:
                                                            theSpeaker?.person
                                                              ?.bio,
                                                        }}
                                                      ></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            }
                                          )}
                                        </div>
                                      ) : null}
                                      {pre?.presentation?.longDescription ? (
                                        <details className="gap-m">
                                          <summary>
                                            <b>Abstract (English)</b>
                                          </summary>
                                          <div
                                            className="gap-l accordion-abstract"
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                pre?.presentation
                                                  ?.longDescription,
                                            }}
                                          ></div>
                                        </details>
                                      ) : null}
                                      {pre?.presentation?.longDescriptionCn ? (
                                        <details className="gap-l">
                                          <summary>
                                            <b>摘要 (Chinese)</b>
                                          </summary>
                                          <div
                                            className="gap-l accordion-abstract"
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                pre?.presentation
                                                  ?.longDescriptionCn,
                                            }}
                                          ></div>
                                        </details>
                                      ) : null}

                                      {pre?.presentation?.presentationUrl ? (
                                        <div
                                          sx={{
                                            marginBottom: `5px`,
                                          }}
                                        >
                                          <a
                                            href={
                                              pre?.presentation?.presentationUrl
                                            }
                                            target="__blank"
                                            rel="noreferrer"
                                          >
                                            <FaInfo
                                              size="18"
                                              color="#3a5d7f"
                                              sx={{
                                                marginRight: `10px`,
                                                mb: `-1px!important`,
                                              }}
                                            />
                                            More about this presentation
                                          </a>
                                        </div>
                                      ) : null}
                                      {pre?.presentation?.files ? (
                                        <ul
                                          sx={{
                                            listStyle: `none`,
                                            margin: `0`,
                                          }}
                                        >
                                          <li
                                            sx={{
                                              paddingRight: `20px`,
                                            }}
                                          >
                                            {pre?.presentation?.files?.map(
                                              (fileMap, index) => {
                                                return (
                                                  <div key={index}>
                                                    {fileMap.file ? (
                                                      <a
                                                        href={
                                                          fileMap.file
                                                            .mediaItemUrl
                                                        }
                                                        target="__blank"
                                                      >
                                                        <FaFileDownload
                                                          size="18"
                                                          color="#3a5d7f"
                                                          sx={{
                                                            marginRight: `10px`,
                                                            mb: `-3px!important`,
                                                          }}
                                                        />
                                                        {fileMap.file.title}
                                                      </a>
                                                    ) : null}
                                                  </div>
                                                )
                                              }
                                            )}
                                          </li>
                                        </ul>
                                      ) : null}
                                      {pre.presentation.youtube ||
                                      pre.presentation.bilibili ? (
                                        <ul
                                          sx={{
                                            listStyle: `none`,
                                            margin: `0`,
                                            display: `flex`,
                                          }}
                                        >
                                          <li
                                            sx={{
                                              paddingRight: `20px`,
                                            }}
                                          >
                                            {pre.presentation.youtube ? (
                                              <a
                                                href={
                                                  pre?.presentation?.youtube
                                                }
                                                target="__blank"
                                              >
                                                <img
                                                  src={YouTube}
                                                  alt="YouTube"
                                                  sx={{
                                                    width: `25px`,
                                                  }}
                                                ></img>
                                              </a>
                                            ) : null}
                                          </li>
                                          <li>
                                            {pre.presentation.bilibili ? (
                                              <a
                                                href={
                                                  pre?.presentation?.bilibili
                                                }
                                                target="__blank"
                                              >
                                                <img
                                                  src={BiliBili}
                                                  alt="BiliBili"
                                                  sx={{
                                                    width: `65px`,
                                                  }}
                                                ></img>
                                              </a>
                                            ) : null}
                                          </li>
                                        </ul>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </AccordionModuleCollapsed>
                )}
              </div>
            )
          })}
          <p sx={{ mt: `5px`, ml: `5px` }}>
            Schedule subject to change without notice.
          </p>
        </div>
      ) : null}
    </div>
  )
}

export default Accordion

/** @jsx jsx */
import { jsx } from "theme-ui"
// import { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"
import Accordion from "../components/SimpleEvents/Schedules"
import SponsorSection from "../components/SimpleEvents/SponsorSection"
import Footer from "../components/footer"
import Obfuscate from "react-obfuscate"
import { GiDiscussion } from "react-icons/gi"
import {
  FaMapMarkerAlt,
  FaArrowRight,
  FaPhone,
  FaEnvelope,
  FaUser,
  FaMailBulk,
  FaCalendar,
  FaCalendarAlt,
} from "react-icons/fa"

const SimpleEvent = props => {
  //const [toggle, setToggle] = useState(false)

  return (
    <Layout>
      <SEO title={`${props.data.wpSimpleEvent.title} | tinyML Foundation`} />
      <div className="hero-event gap-xl">
        <div className="hero-event-section">
          {props.data.wpSimpleEvent.simpleEvents.heroImage?.localFile
            ?.childImageSharp.fluid ? (
            <Img
              fluid={
                props.data.wpSimpleEvent.simpleEvents.heroImage?.localFile
                  ?.childImageSharp.fluid
              }
              alt={props.data.wpSimpleEvent.simpleEvents?.heroImage?.altText}
            />
          ) : (
            <Img fluid={props.data.placeholder.childImageSharp.fluid} />
          )}
          <div className="hero-event-section-banner">
            <div className="grid-container hero-event-content">
              {props.data.wpSimpleEvent?.title ? (
                <h2>{props.data.wpSimpleEvent?.title}</h2>
              ) : null}

              <div>
                {props.data.wpSimpleEvent?.simpleEvents?.button1Group
                  ?.button1Label !== null ||
                props.data.wpSimpleEvent?.simpleEvents?.button1Group
                  ?.button1Url !== null ? (
                  <a
                    target="__blank"
                    href={
                      props.data.wpSimpleEvent?.simpleEvents?.button1Group
                        ?.button1Url
                    }
                  >
                    <button
                      className="hero-event-section-buttons"
                      aria-label="Button"
                    >
                      {
                        props.data.wpSimpleEvent?.simpleEvents?.button1Group
                          ?.button1Label
                      }
                    </button>{" "}
                  </a>
                ) : null}
                {props.data.wpSimpleEvent?.simpleEvents?.button2Group
                  ?.button2Label !== null ||
                props.data.wpSimpleEvent?.simpleEvents?.button2Group
                  ?.button2Url !== null ? (
                  <a
                    target="__blank"
                    href={
                      props.data.wpSimpleEvent?.simpleEvents?.button2Group
                        ?.button2Url
                    }
                  >
                    <button
                      className="hero-event-section-buttons"
                      aria-label="Button"
                    >
                      {
                        props.data.wpSimpleEvent?.simpleEvents?.button2Group
                          ?.button2Label
                      }
                    </button>
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid-container gap-xxxl">
        <div className="grid-x grid-margin-x grid-margin-y">
          {props.data.wpSimpleEvent?.simpleEvents?.simpleDescription !== null &&
          props.data.wpSimpleEvent?.simpleEvents?.simpleDescription !== "" ? (
            <div
              className="large-6 cell sum2020"
              dangerouslySetInnerHTML={{
                __html:
                  props.data.wpSimpleEvent?.simpleEvents?.simpleDescription,
              }}
            ></div>
          ) : null}
          <div className="large-6 medium-6 cell gap-xl">
            <div>
              <h3 style={{ color: `#faa23a`, fontWeight: `bold` }}>Date</h3>
              <div className="event-venue">
                <FaCalendarAlt
                  className="event-venue__marker"
                  size="55"
                  color="#3a5d7f"
                />
                <div
                  className="event-contact__info"
                  sx={{
                    position: `relative`,
                    alignSelf: `center`,
                    width: `max-content`,
                    height: `50%`,
                  }}
                >
                  <h4>
                    {props.data.wpSimpleEvent?.simpleEvents?.simpleDateRange}
                  </h4>
                </div>
              </div>
            </div>
            <div>
              <h3 style={{ color: `#faa23a`, fontWeight: `bold` }}>Location</h3>
              <div className="event-venue">
                <FaMapMarkerAlt
                  className="event-venue__marker"
                  size="55"
                  color="#3a5d7f"
                />
                <div
                  className="event-contact__info"
                  sx={{
                    position: `relative`,
                    alignSelf: `center`,
                    width: `max-content`,
                    height: `50%`,
                  }}
                >
                  <h4>{props.data.wpSimpleEvent?.simpleEvents?.location}</h4>
                </div>
              </div>
            </div>

            {props.data.wpSimpleEvent?.simpleEvents?.contactEmail ? (
              <div>
                <h3 style={{ color: `#faa23a`, fontWeight: `bold` }}>
                  Contact us
                </h3>

                <div className="event-contact">
                  <FaEnvelope
                    className="event-venue__marker"
                    size="55"
                    color="#3a5d7f"
                  />
                  <div
                    className="event-contact__info"
                    sx={{
                      position: `relative`,
                      alignSelf: `center`,
                      width: `max-content`,
                      height: `50%`,
                    }}
                  >
                    <h4>
                      <Obfuscate
                        style={{ marginRight: `20px`, color: `#3a5d7f` }}
                        href={`mailto:${props.data.wpSimpleEvent?.simpleEvents?.contactEmail}`}
                        target="__blank"
                      >
                        Write an Email
                      </Obfuscate>
                    </h4>
                  </div>
                </div>
              </div>
            ) : null}
            {props.data.wpSimpleEvent.simpleEvents?.forumLink ? (
              <div>
                <h3 style={{ color: `#faa23a`, fontWeight: `bold` }}>
                  Discussion
                </h3>

                <div className="event-contact">
                  <GiDiscussion
                    className="event-venue__marker"
                    size="55"
                    color="#3a5d7f"
                  />
                  <div
                    className="event-contact__info"
                    sx={{
                      position: `relative`,
                      alignSelf: `center`,
                      width: `max-content`,
                      height: `50%`,
                    }}
                  >
                    <h4>
                      <a
                        style={{ marginRight: `20px`, color: `#3a5d7f` }}
                        href={props.data.wpSimpleEvent.simpleEvents?.forumLink}
                        target="__blank"
                      >
                        Forum
                      </a>
                    </h4>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <Accordion accordion={props.data.wpSimpleEvent} />
      {props.data.wpSimpleEvent?.simpleEvents?.displaySponsorSection ===
      true ? (
        <SponsorSection sponsors={props.data.wpSimpleEvent} />
      ) : null}
      <Footer />
    </Layout>
  )
}
export const query = graphql`
  query($slug: String) {
    wpSimpleEvent(slug: { eq: $slug }) {
      date
      id
      title
      simpleEvents {
        heroImage {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        collapsedSimpleEventSchedule
        button1Group {
          button1Label
          button1Url
        }
        button2Group {
          button2Label
          button2Url
        }
        contactEmail
        simpleDescription
        simpleDateRange
        displaySponsorSection
        forumLink
        location
        timeSlots {
          timeZone
          startTime
          endTime
          presentations {
            ... on WpPresentation {
              date(formatString: "dddd MMMM Do, YYYY")
              id
              title
              presentation {
                alternativeTitleChinese
                moderator {
                  ... on WpPerson {
                    id
                    person {
                      personName
                      jobTitle
                      company
                    }
                  }
                }
                youtube
                presentationUrl
                bilibili
                shortDescription
                shortDescriptionCn
                longDescription
                longDescriptionCn
                files {
                  file {
                    mediaItemUrl
                    title
                    localFile {
                      name
                      publicURL
                    }
                  }
                }
                presentationSpeaker {
                  ... on WpPerson {
                    id
                    title
                    person {
                      personName
                      jobTitle
                      company
                      phone
                      mailAddress
                      linkedinUrl
                      bio
                      personImage {
                        localFile {
                          childImageSharp {
                            fluid(maxWidth: 1500, quality: 100) {
                              ...GatsbyImageSharpFluid
                            }
                          }
                        }
                      }
                      id
                    }
                  }
                }
              }
            }
          }
        }
        sponsorLevelTitles {
          levelOne
          levelTwo
          levelThree
          levelFour
          levelFive
          levelSix
        }
        ranks {
          sponsor {
            ... on WpSponsor {
              id
              title
              sponsorDetails {
                sponsorLink
                sponsorDescription
                contactEmail
                upperImage {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
                lowerImage {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
                lowerMediaFileType
                lowerVideoCopy
                upperMediaFileType
                upperVideo
                contactEmail
                contactUrl
                emailButtonLabel
                urlButtonLabel
                contactInfo
                buttonsCheckbox
              }
              featuredImage {
                node {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1200, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    placeholder: file(relativePath: { eq: "headway-unsplash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2400, maxHeight: 800, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
export default SimpleEvent
